<template>
    <div class="corp-sale flex-item scroll-div province-portion-data">
        <div class="portion-content"
            :style="{ 'align-items': message ? 'center' : 'unset', 'justify-content': message ? 'center' : 'unset' }">
            <template v-if="message">
                <ts-error></ts-error>
            </template>
            <template v-else>
                <div class="portion-content_left portion-content_item">
                    <ts-table ref="table" class="flex-item" :cols="distribution.cols" :data="distribution.list"
                        :loading="distribution.loading" hide-page hide-detail hide-border :height="height"
                        :summaryMethod="summaryFun2">
                        <template #left>
                            <div class="ts-table-left">
                                <div style="display: flex;align-items: center;">
                                    <div class="portion-title">药品销售省份分布</div>
                                    <el-select class="show-cart" v-model="comLeftObj.value" style="width: 200px;"
                                        filterable remote reserve-keyword :remote-method="remoteMethodComLeft"
                                        @change="comChangeLeft" :loading="comLeftObj.loading">
                                        <el-option v-for="item in comLeftObj.options" :key="item.value"
                                            :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </div>
                                <el-button class="txt-btn" type="text" icon="el-icon-document"
                                    @click="clickExport('province/saleProvince/exportComSaleProvince')">导出</el-button>
                            </div>
                        </template>
                        <template v-slot:slot_trend="{ props }">
                            <div @click="showCanvas(props.row)" class="icon">
                                <i class="iconfont icon-a-rongqi1"></i>
                            </div>
                        </template>
                        <template v-slot:slot_amount="{ props }">
                            <div :class="[props.row.rate > 0 ? 'up' : 'down']">
                                {{ props.row.amount || '' }}
                            </div>
                        </template>
                        <template v-slot:slot_upOrDown="{ props }">
                            <div :class="[props.row.rate > 0 ? 'up' : props.row.rate < 0 ? 'down' : '']">
                                {{ props.row.upOrDown }}
                            </div>
                        </template>
                    </ts-table>
                </div>
                <div class="portion-content_right portion-content_item">
                    <ts-table class="flex-item" :cols="cols" hide-border :data="list" :page-param="pageParam"
                        :loading="loading" hide-detail height="65vh" @handleSizeChange="handleSizeChange"
                        :summaryMethod="summaryFun" @handleSort="sort" stop-empty-hide
                        @handleCurrentChange="handleCurrentChange">
                        <template #left>
                            <div class="ts-table-left">
                                <div style="display: flex;align-items: center;">
                                    <div class="portion-title">各历年销售数据</div>
                                    <el-select class="show-cart" v-model="comLeftObj.value" style="width: 200px;"
                                        filterable remote reserve-keyword :remote-method="remoteMethodComLeft"
                                        @change="comChangeLeft" :loading="comLeftObj.loading">
                                        <el-option v-for="item in comLeftObj.options" :key="item.value"
                                            :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </div>
                                <el-button class="txt-btn" type="text" icon="el-icon-document"
                                    @click="clickExport('province/saleProvince/exportProvinceComSale')">导出</el-button>
                            </div>
                        </template>
                        <template v-slot:slot_upOrDown="{ props }">
                            <div
                                :class="[props.row.upOrDown === '涨' ? 'up' : props.row.upOrDown === '跌' ? 'down' : '']">
                                {{ props.row.upOrDown }}
                            </div>
                        </template>
                    </ts-table>
                </div>
            </template>

        </div>
        <ts-dialog :show.sync="dialog.show" :title="dialog.title" close-on-click-modal hide-footer width="1000px">
            <div :id="echartId" class="canvas"></div>
        </ts-dialog>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    props: ['mainKey'],
    mixins: [myMixin],
    data() {
        return {
            message: '',
            currentParam: {
                page: 'province/saleProvince/provinceComSale',
                export: '',
            },
            stop: true,
            height: '65vh',
            sumData2: [],
            echartId: 'province-portion-echart',
            cols: this.$help.generalCols([{
                fieldName: 'provinceName',
                name: '省份',
                width: 40,
                listQueryView: '是',
                listQueryModel: 1,
            },
            {
                fieldName: 'unifySpec',
                name: '规格',
                width: 40,
                listQueryView: '是',
                listQueryModel: 1
            },
            {

                fieldName: 'compName',
                name: '企业',
                width: 100,
                listQueryView: '是',
                listQueryModel: 1
            },
            ...this.$variable.getYearCols({
                listOrderView: '是',
            }),
            {

                fieldName: 'upOrDown',
                name: '异常分析',
                width: 40,
                listQueryView: '是',
                listQueryModel: 1,
                listClass: 'tc'
            },
            ]),
            //各省分布
            distribution: {
                cols: this.$help.generalCols([
                    {
                        fieldName: 'upOrDown',
                        name: '近1年趋势',
                        width: 60,
                        listQueryView: '是',
                        listQueryModel: 1,
                        listOrderView: true,
                        listClass: 'tc'
                    },
                    {
                        fieldName: 'provinceName',
                        name: '省份',
                        width: 80,
                        listQueryView: '是',
                        listOrderView: true,
                        listQueryModel: 1,
                    },
                    {
                        fieldName: 'amount',
                        name: '近年销售额',
                        width: 60,
                        listQueryView: '是',
                        listOrderView: true,
                        listQueryModel: 1
                    },
                    {
                        fieldName: 'trend',
                        name: '历年销售趋势',
                        width: 60,
                        listQueryView: '是',
                        listQueryModel: 1,
                        listClass: 'tc'
                    },]),
                list: [],
                loading: false,
            },
            comLeftObj: {
                value: '',
                comName: '',
                loading: false,
                options: [],
            },
            comRightObj: {
                value: '',
                comName: '',
                loading: false,
                options: [],
            },
            dialog: {
                show: false,
                title: '历年销售趋势'
            }
        }
    },
    mounted() {
        this.getComList('红花逍遥', true)
    },
    methods: {
        paramFunc(query) {
            query.params = {
                comCode: this.comLeftObj.value
            }
            return query
        },
        getComList(query = '', bool = false) {
            this.comLeftObj.loading = true
            this.comRightObj.loading = true
            this.$api.get('setting/aimBaseDrugs/queryChineseAimBaseDrugsListByKeyword', { keyword: query }).then(res => {
                if (res.code === 'A0004') {
                    this.message = true
                }
                let options = []
                if (res.data && res.data.length !== 0) {
                    res.data.forEach(ele => {
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comLeftObj.options = options
                this.comRightObj.options = options
                if (bool && options.length !== 0) {
                    this.comLeftObj.value = options[0].value
                    this.comLeftObj.comName = options[0].label
                    this.comRightObj.value = options[0].value
                    this.comRightObj.comName = options[0].label
                }
                this.getDisList()
                this.init()
            }).finally(() => {
                this.comLeftObj.loading = false
                this.comRightObj.loading = false
            })
        },
        //药品搜索
        remoteMethodComLeft(query) {
            this.getComList(query, false)
        },
        comChangeLeft() {
            this.getDisList()
            this.init()
        },

        //药品销售省份分布
        getDisList() {
            this.distribution.loading = true
            this.$api.get('province/saleProvince/comSaleProvince', {
                comCode: this.comLeftObj.value
            }).then(res => {
                if (res.code === 'A0004') {
                    this.message = true
                }
                let list = []
                if (res.success) list = res.data
                this.distribution.list = list
                if (res.dataMap && res.dataMap.sumData) {
                    this.sumData2 = { "amount": res.dataMap.sumData }
                }
            }).finally(() => {
                this.distribution.loading = false
            })
        },

        showCanvas(row) {
            this.dialog.show = true
            this.$api.get('province/saleProvince/comSaleProvinceTrend', {
                comCode: this.comLeftObj.value,
                provinceCode: row.provinceCode
            }).then(res => {
                this.$help.renderBar(this.echartId, res.data, 'saleYear')
            })
        },
        //导出
        clickExport(url) {
            this.currentParam.export = url
            if (url === 'province/saleProvince/exportProvinceComSale') {
                this.leadingOut()
            } else {
                this.$store.commit('confirm', {
                    content: '确定导出吗',
                    callback: (next, back) => {
                        this.$api.get(url, { comCode: this.comLeftObj.value })
                            .then(res => {
                                if (!(res.success || res.code === 'N0001')) {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === 'N0001') {
                                    next()
                                    this.exportCorpInfo(res.message, { force: true })
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            }
        },
        summaryFun2(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计'
                } else {
                    sums[index] = this.sumData2[column.property]
                }
            })
            return sums
        },
    },

}
</script>
<style lang="scss">
@import "~@/assets/css/var";

.province-portion-data {
    position: relative;
    padding-top: 10px;

    .show-cart {
        margin: 0 20px 0 10px;
    }

    .portion-content {
        display: flex;

        .ts-table-left {
            justify-content: space-between;
        }

        &_item {
            .flex-item {
                padding-top: 0;

                .icon {
                    color: $main;
                    cursor: pointer;
                }

                .ts-table-left {
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;

                    .portion-title {
                        font-size: 15px;
                        font-weight: bold;
                    }
                }

                .up {
                    color: $red;
                }

                .down {
                    color: $green;
                }

                .flex {
                    padding-bottom: 0 !important;
                }
            }
        }

        &_left {
            width: 30%;
        }

        &_right {
            width: 70%;

            .flex-item {
                padding-left: 0;
            }
        }
    }
}
</style>